<template>
  <div class="main">
    <div class="chat">
      <!-- 顶部tap切换开始 -->
      <div v-if="isShow" class="tap-box">
        <div @click="toIndex" class="tap-text-box">
          <div class="tap-def-text">聊天助手</div>
          <div class="tap-def-line"></div>
        </div>
        <div @click="toSource" class="tap-text-box">
          <div class="tap-def-text">素材</div>
          <div class="tap-def-line"></div>
        </div>
        <div class="tap-text-box">
          <div class="tap-select-text">答题</div>
          <div class="tap-select-line"></div>
        </div>
      </div>
      <!-- 顶部tap切换结束 -->
      <div class="search-box">
        <div class="search-box-input">
          <van-search v-model="queryPostData.keyword" placeholder="请输入关键词" @input="showList"/>
        </div>
      </div>
      <van-list
              v-model="loading"
              :finished="finished"
              :immediate-check="false"
              finished-text="没有更多信息了"
              @load="onLoad"
      >
        <div class="list-main-box" v-for="item in list" :key="item.id">
          <div class="list-main">
            <div v-if="item.status === 1 && item.share_path !== ''" @click="initData(item)"><img class="share-icon" src="../assets/image/share.png"></div>
            <div v-else style="width: 50px"></div>
            <div @click="toUrl(item)" class="list-img-box">
              <van-image width="55" height="55" show-error :src="item.thumbMediaId" />
            </div>
            <div @click="toUrl(item)" class="list-box">
              <div class="list-title-box">
                <div class="list-title" v-html="item.title"></div>
              </div>
              <div class="list-content" v-if="item.status !== 1"><span style="color: #FF4336">视频因违规已删除</span></div>
            </div>
          </div>
          <div class="list-label">{{item.className}}</div>
        </div>
      </van-list>
      <div class="bottom-margin"></div>
    </div>
  </div>
</template>
<script>
import { getJsSdkConfig, getVideoList, addShareLog } from '@/api/service'
import Cookies from 'js-cookie'
import { Toast } from 'vant';
import { Dialog } from 'vant';
const wx = window.wx;
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      userId: '', // 用户id
      nowUserId: '', // 外部联系人id
      nowUserInfo: {}, // 当前用户信息
      configPostData: {
        agentId: 1000069,
        url: ''
      },
      jsSdkConfig: {},
      userPostData: {
        userid: '',
        externalUserid: ''
      },
      tagsAll : [],
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      total:0,
      disableType:false,
      queryPostData: {
        mediaType: '',
        keyword: '',
        page: 1,
        size: 10
      },
      radio: 1,
      buttonClass: 'bottom-left1',
      buttonRightClass: 'bottom-right1',
      buttonWord: '新增话术',
      selectItem: {},
      isOriginHei: true,  //显示或者隐藏button
      inputFocus: false,
      documentHeight: document.documentElement.clientHeight,
      isSelf: false,
      labelData:[{
        select:0,
        type: 0,
        typename:'聊天助手'
      },{
        select:0,
        type: 1,
        typename:'素材'
      }
      ],
      tapType: 1, // 当前选择tap
      isShow:true,
      tapLabel:['label-def','label-def','label-def','label-def'],
      isShop: false
    }},
  created() {
    // Cookies.set('userId', 'Wangleiqiang')
    let userId=Cookies.get('userId')
    this.queryPostData.userid = userId
    this.userPostData.userid = userId
    this.userId = userId
    if(!userId){
      this.$router.push('/');
    }else{
      // 获取jssdk 配置的信息
      this.getConfig('config')
      this.onLoad()
      this.userId=userId
    }
  },
  mounted:function(){
  },
  methods: {
    // 获取sdk  配置
    getConfig() {
      let that = this
      this.configPostData.url = window.location.href;
      getJsSdkConfig(this.configPostData).then(response => {
        that.initAgentConfig(response.data);
      })
    },
    onLoad() {
      this.getVideoList()
      // 加载状态结束
      this.loading = false;
      this.refreshing = false;
    },
    // 获取视频答题列表
    getVideoList(){
      if(this.disableType === true){
        return false
      }
      this.disableType = true;
      let postData = {}
      postData.limit = this.queryPostData.size
      postData.page = this.queryPostData.page
      postData.keyword = this.queryPostData.keyword
      postData.userId = Cookies.get('shopUserId')
      postData.shopId = Cookies.get('shopId')
      getVideoList(postData).then(response => {
        this.disableType=false;
        let nowPage=this.queryPostData.page
        this.queryPostData.page=nowPage+1
        let newData = {}
        for (let i = 0; i < response.data.data.length; i++) {
          let nowData = response.data.data[i]
          newData = {}
          newData.title = nowData.video_name
          // newData.thumbMediaId = nowData.thumb
          newData.thumbMediaId = 'https://newplatform-1301970825.cos.ap-beijing.myqcloud.com/images/2022-02-17/1645069481418605.png'
          newData.digest = ''
          newData.mediaType = 3
          newData.materialUrl = nowData.video_address
          newData.status = nowData.status
          newData.share_path = nowData.share_path.replace('index','index.html')
          newData.className = this.getClass(3)
          this.list.push(newData);
        }
        if(this.list.length>=response.data.total){
          this.finished = true;
        }
      }, error => {alert(error)})
    },
    // 获取标签名称
    getClass(num) {
      switch (num) {
        case 0:
          return '文章'
        case 1:
          return '图片'
        case 2:
          return '文件'
        case 3:
          return '答题'
      }
    },
    showList() {
      this.finished = false
      this.list = []
      this.queryPostData.page=1
      this.getVideoList()
      // 加载状态结束
      this.loading = false;
      this.refreshing = false;
    },
    // 跳转到首页
    toIndex(){
      this.$router.push({ path: '/Home', query: {} });
    },
    // 跳转到素材列表
    toSource(){
      this.$router.push({ path: '/Source', query: {} });
    },
    // 获取进入页面的来源
    getFrom() {
      let that = this
      wx.invoke('getContext', {
      }, function(res){
        console.log(res)
        if(res.err_msg == "getContext:ok"){
          let entry  = res.entry ; //返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment
          if (entry !== 'single_chat_tools' && entry !== 'group_chat_tools') {
            //错误处理
            Dialog.alert({
              title: '错误',
              message: '请从聊天窗口打开！',
            }).then(() => {
              // on close
              window.location.href="about:blank";
            });
          }else{
            that.getNowUser()
          }
        }else {
          //错误处理
          return
        }
      });
    },
    // 获取外部联系人id
    getNowUser() {
      let that = this
      // 获取外部联系人id
      wx.invoke('getCurExternalContact', {
      },function(res){
        console.log(res)
        if(res.err_msg == "getCurExternalContact:ok"){
          that.nowUserId  = res.userId ; //返回当前外部联系人userId
        }else {
          return
        }
      })
    },
    // 格式化发送数据
    initData(data){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      let postData = {}
      postData = {
        msgtype: "miniprogram", //消息类型，必填
        enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
        miniprogram: {
          appid: "wxd4abb928b02b00b9",//小程序的appid，企业已关联的任一个小程序
          title: data.title, //小程序消息的title
          imgUrl:data.thumbMediaId,//小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
          page:data.share_path, //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
        }
      }
      console.log(postData)
      this.sendToUser(postData,data)
    },
    // 发送消息到客户聊天窗口
    sendToUser(postData,data) {
      let that = this
      wx.invoke('sendChatMessage', postData,function(res) {
        if (res.err_msg == 'sendChatMessage:ok') {
          //发送成功
          // that.pushData()
          console.log('ok')
          that.pushData(data)
        }
        Toast.clear()
      })
    },
    // 跳转到对应的链接
    toUrl(data){
      /* window.location.href = data.materialUrl */
      window.open(data.materialUrl, '_blank');
    },
    // 推送发送记录到数据库
    pushData(data) {
      let postData = {}
      postData.userId = this.userId
      postData.extenalUserid = this.nowUserId
      postData.materialId = data.id
      addShareLog(postData).then(response => {
        console.log(response)
      })
    },
    // 配置jssdk
    initJsSdk(jsSdkConfig) {
      let that = this
      wx.config({
        beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: jsSdkConfig.appId, // 必填，企业微信的corpID
        timestamp: jsSdkConfig.timestamp, // 必填，生成签名的时间戳
        nonceStr: jsSdkConfig.nonceStr, // 必填，生成签名的随机串
        signature: jsSdkConfig.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: ['getCurExternalContact','sendChatMessage','openEnterpriseChat'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      })
      wx.ready(function(){
        console.log('初始化js sdk 成功')
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        that.getConfig('agent_config')
      })
      wx.error(function(res){
        console.log(res)
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      })
    },
    // 初始化 agentConfig
    initAgentConfig(jsSdkConfig) {
      let that = this
      wx.agentConfig({
        corpid: jsSdkConfig.appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: '1000069', // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: jsSdkConfig.timestamp, // 必填，生成签名的时间戳
        nonceStr: jsSdkConfig.nonceStr, // 必填，生成签名的随机串
        signature: jsSdkConfig.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
        jsApiList: ['getCurExternalContact','sendChatMessage','getContext'], //必填，传入需要使用的接口名称
        success: function() {
          // 回调
          console.log('初始化agent 成功')
          that.getFrom()
        },
        fail: function(res) {
          console.log(res)
          if(res.errMsg.indexOf('function not exist') > -1){
            alert('版本过低请升级')
          }
        }
      });
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .main{
    background-color:#ffffff;
  }
  .chat{
    background-color:#ffffff;
    padding: 20px;
    min-height: 100vh;
  }
  .search-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 0 10px 0;
  }
  .search-box-input{
    width: 100%;
  }
  .van-search {
    padding: 0 !important;
  }
  .list-main-box{
    border-bottom: 1px solid #E5E5E5;
    margin-top: 20px;
  }
  .list-box{
    margin: 10px 0 0 15px;
    width: 75vw;
    overflow: hidden;
    text-overflow:ellipsis;
  }
  .list-title{
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    line-height: 20px;
    display:-webkit-box;/**对象作为伸缩盒子模型展示**/
    -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
    -webkit-line-clamp:2;/**显示的行数**/
    overflow:hidden;/**隐藏超出的内容**/
  }
  .list-title-box{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .list-title-img{
    width: 50px;
    height: 50px;
    vertical-align: center;
    margin-right: 5px;
  }
  .list-content{
    margin: 5px 0 10px 0;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
    display:-webkit-box;/**对象作为伸缩盒子模型展示**/
    -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
    -webkit-line-clamp:2;/**显示的行数**/
    overflow:hidden;/**隐藏超出的内容**/
  }
  .bottom-margin{
    height: 100px;
  }
  .search-button{
    background-color: #2586FF;
    color: #ffffff;
    border-radius: 5px;
    width: 70px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-size: 15px;
  }
  .list-main{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .share-icon{
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .list-label{
    font-size: 12px;
    width: 50px;
    height: 20px;
    line-height: 20px;
    background-color: #94c4f1;
    color: #ffffff;
    border-radius: 3px;
    text-align: center;
    margin: 5px 0 15px 40px ;
  }
  .top-label-box{
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 15px;
  }
  .label-select{
    width: 70px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #007AFF;
    color: #ffffff;
    border-radius: 3px;
  }
  .label-def{
    width: 70px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #dfe1e3;
    color: #000000;
    border-radius: 3px;
  }
  /* tap切换开始 */
  .tap-box{
    display: flex;
    justify-content: space-around;
    margin-bottom:20px;
  }
  .tap-text-box{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .tap-select-text{
    font-size: 16px;
    color: #007AFF;
    font-weight: bold;
  }
  .tap-select-line{
    background-color: #007AFF;
    height: 3px;
    margin-top: 10px;
  }
  .tap-def-text{
    font-size: 14px;
    color: #000000;
    font-weight: bold;
  }
  .tap-def-line{
    background-color: #ffffff;
    height: 3px;
    margin-top: 10px;
  }
  .type-icon{
    width: 55px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    font-weight: bold;
    background-color: #C03639;
    border-radius: 3px;
  }
  /* tap切换结束 */
</style>
